import React from 'react'
import Layout from '../components/layout'
import * as styles from './contact.module.css'

const ContactFormPage = () => (
  <Layout>
    <div className={styles.formBox}>
      <h5>Contact</h5>
      <form name='contact' method='POST' netlify-honeypot='bot-field' data-netlify='true' action='/thank-you'>
        <input type='hidden' name='bot-field' />
        <input type='hidden' name='form-name' value='contact' />
        <label>
          Name
          <input type='text' name='name' id='name' />
        </label>
        <label>
          Email
          <input type='email' name='email' id='email' />
        </label>
        <label>
          Subject
          <input type='text' name='subject' id='subject' />
        </label>
        <label>
          Message
          <textarea name='message' id='message' rows='5' />
        </label>
        <input type='submit' value='Submit'/>
        <input type='reset' value='Clear'/>
      </form>
    </div>
  </Layout>
)


export default ContactFormPage
